import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>404: Not Found</h1>
    <section className="contact">
      <div className="content">
        <h2>CONTACT</h2>
        <h3>Irene Friis</h3>
        <h3><a href="tel:+4522527375">Tel. 22 52 73 75</a></h3>
        <h3><a href="mailto:irene@lr-friis.dk">irene@lr-friis.dk</a></h3>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
